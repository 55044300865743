import { graphql } from 'gatsby'
import React from 'react'
import { Button, Columns, Container, Heading, Section } from 'react-bulma-components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Layout from '../../components/layout'
import UserFormFields from '../../components/users/form/user-fields'
import { useSession } from '../../stores/session'


const UserProfile = () => {
  const [sessionState, sessionActions] = useSession()
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: !sessionState.currentUser ? {} : {
      firstName: sessionState.currentUser.firstName,
      lastName: sessionState.currentUser.lastName,
      email: sessionState.currentUser.email,
      phone: sessionState.currentUser.phone,
      fax: sessionState.currentUser.fax,
    }
  })
  const onSubmit = data => {
    sessionActions.updateUser(data)
  }
  return (
    <Layout pro>

      <Section size='medium'>
        <Container>
          <Columns centered>
            <Columns.Column size={6}>
              <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
                {t('account:profile.title')}
              </Heading>

              <form onSubmit={handleSubmit(onSubmit)}>
                <UserFormFields
                  title={t('account:profile.sub_title')}
                  control={ control }
                  defaultValues={sessionState.currentUser}
                  disabled={{ email: true }}
                  noPassword
                />
                <Button>{t('account:profile.submit')}</Button>
              </form>
            </Columns.Column>
          </Columns>
        </Container>
      </Section >
    </Layout >
  )
}
export default UserProfile

export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`